import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
.dialog-backdrop{
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  background-color: rgb(0 0 0 / 61%);
}
.modal-basic{
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.modal{
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;

}

.scroll {
  overflow-y: auto;
}
`

export const closeButton = css`
  .modal-close {
    background-color: ${colors.elevatedBg};
    width: 38px;
    height: auto;
    line-height: 40px;
    position: absolute;
    right: 14px;
    top:12px;
    content: url('/img/cross-icon-no-bg.svg');
    color: ${colors.primaryText};
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    font-weight: 700;
    outline: 2px;
    cursor: pointer;
    padding: 0;
    border: 0;
  }

  .modal-close:hover:after {
    border-radius: 100px;
    background: ${colors.baseBg};
  }
  .modal-close:after {
    font-size: 32px;
    content: url('/img/cross-icon-no-bg.svg');
    color: ${colors.primaryText};
    text-indent: 0;
    position: absolute;
    top: 0px;
    display: block;
    height: 100%;
    width: 100%;
    font-weight: 300;
  }
  @media (min-width: 767px){
    .modal-close {
      top:16px;
      width: 34px;
      height: 34px;
    }
  }
`
export const modalBody = css`
  .modal-body{
    display: flex;
    width: 496px;
    flex-direction: column;
    color: ${colors.primaryText}
    font-size: 14px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    width: 100%;
    background-color: ${colors.elevatedBg};
  }
  .modal-body :global(p),
  .modal-text{
    color: ${colors.primaryText};
    font-size: 14px;
    line-height: normal;
    margin: 16px 0px 32px 0px;
  }

  .modal-body :global(p+p),
  .modal-text+.modal-text {
    margin-top: 0.5em;
  }

  .modal-body strong,
  .modal-text strong {
    font-weight: 700;
  }

  .modal-body :global(.medium),
  .modal-text.medium {
    font-size: 1em;
    margin: 0;
  }

  .modal-body :global(.large),
  .modal-text.large {
    font-size: 1.2em;
    font-weight: 700;
    margin: 1em 0;
  }

  .modal-body :global(ul) {
    margin: 24px 0 32px;
    padding: 0;
  }

  .modal-body :global(.list-items) {
    margin: 0;
  }

  .modal-body :global(li) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .modal-body :global(li:nth-of-type(1) span::after,
  .terms p:nth-of-type(1)::before) {
    content: '*';
  }

  .modal-body :global(li:nth-of-type(3) span::after,
  .terms p:nth-of-type(2)::before) {
    content: '**';
  }

  .modal-body :global(.tick) {
    margin: 10px 10px 0 0;
    color: ${colors.green};
  }

  .modal-body :global(.centre) {
    text-align: center;
  }
  @media (min-width: 600px) {
    .modal-body :global(p),
    .modal-text{
      font-size: 14px;
      line-height: 22px;
    }

    .modal-body :global(.medium),
    .modal-text.medium {
      font-size: 1.5em;
    }

    .modal-body :global(.large),
    .modal-text.large {
      font-size: 1.5em;
      line-height: 2rem;
      text-align: left;
      font-weight: normal;
    }

    .modal-body :global(.list),
    .modal-text.list {
      font-size: 1.75em;
      margin: 0.5em 0 0.75em 0;
      text-align: left;
      font-weight: 700;
    }
  }
`

export const modalHeader = css`
  .modal-header {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin: 0;
    color: ${colors.primaryText};
    font-size: 28px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    width: 100%;
  }

  @media (max-width: 600px){
    .modal-header {
      font-size: 24px;
    }
  }
`
export const modalLayout = css`
.modal-layout {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  background-color: ${colors.elevatedBg};
  padding-bottom: 22px;
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  text-align: left;
  align-items: left;
  border-radius: 8px;
  left: 16px;
  right: 16px;
}

@media (min-width: 600px) {
  .modal-layout {
    width: 550px;
    padding: 24px;
    min-width: 500px;
    left: 0px;
    right: 0px;
  }
  .small-modal {
    max-width: 700px;
  }
  .large-modal {
    max-width: 900px
  }
}
`
export const modalButtons = css`
.modal-buttons :global(.button) {
  border: none;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  text-align: center;
  transition: background .5s ease-in-out;
  white-space: normal;
  padding: 15px 40px;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  transition: 0.2s;
  background-color: ${colors.IconButtonPurple};
  color: ${colors.primaryText};
}

.modal-buttons :global(.secondary) {
  background-color: transparent;
  color: ${colors.primaryText};
  border: 1px solid transparent;
}

.modal-buttons :global(.warning) {
  background: ${colors.warning};
}

.modal-buttons :global(.warning):hover{
  background: #8A181F;
}

.modal-buttons :global(.secondary):hover {
  border-radius: 8px;
border: 1px solid ${colors.primaryText};
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

}

.modal-buttons {
  width: 100%;
  display: flex;
  justify-content: right;
}

.modal-buttons :global(a) {
  text-decoration: none;
}

.modal-buttons :global(.modal-button){
  padding: 13px 40px;
  margin: 10px;
}

@media (max-width: 600px){
  .modal-buttons {
   flex-direction: column-reverse;
  }
  .modal-buttons :global(.modal-button){
    margin: 0px 0px 8px 0px;
  }
}
`
