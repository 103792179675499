import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { HamburgerIcon, CrossIcon } from '../icons'
import StvLogo from '../StvLogo'
import makeIconLink from '../../HOCs/makeIconLink'
import HeaderLoginLink from '../HeaderLoginLink'
import styles from './styles'
import links from './config'
import { getCurrentDomain, getCurrentUrl, getCurrentPath } from '../../modules/utils'
import withUser from '../../HOCs/withUser'
import AccountSummary from '../AccountSummary'
import { getSubscriptionInfo } from '../../initLoginService'

const HamburgerLink = makeIconLink(HamburgerIcon)
const CURRENT_DOMAIN = getCurrentDomain()
const CURRENT_URL = getCurrentUrl()

const HeaderMenu = ({ user }) => {
  const [menuActive, setMenuActive] = useState(false)
  const [modifiedLinks, setModifiedLinks] = useState(links)
  const currentPath = getCurrentPath()
  const { currentlySubscribed } = getSubscriptionInfo(user['custom:subscriptions'])
  // arbitraty state to update when nav link clicked to ensure rerender
  const [clicked, setClicked] = useState(false)
  const plusLink = {
    path: 'https://player.stv.tv/player-plus',
    title: 'Subscribe to STV Player+',
  }

  // disable body scrolling when mobile menu open
  useEffect(() => {
    if (menuActive) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = null
    }
  }, [menuActive])

  useEffect(() => {
    if (currentlySubscribed || window.innerWidth >= 767) {
      setModifiedLinks(links)
    } else if (!currentlySubscribed && window.innerWidth < 768) {
      setModifiedLinks([...links, plusLink])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySubscribed])

  const onBlur = (e) => {
    if (
      !e.currentTarget.contains(e.relatedTarget)
    ) {
      setMenuActive(false)
    }
  }

  const navClickHandler = (e) => {
    e.preventDefault()
    setMenuActive(false)
    setClicked(!clicked)
  }

  const toggleMenu = (e) => {
    e.preventDefault()
    setMenuActive(!menuActive)
  }

  const hideMenu = (e) => {
    e.preventDefault()
    setMenuActive(false)
  }

  return (
    <div className="HeaderMenu">
      <div className="inner">
        <div className="logo-wrapper">
          <StvLogo />
        </div>
        <nav
          className="nav"
        >
          <div
            className={`nav-container ${menuActive ? 'menu-active' : ''}`}
            onBlur={onBlur}
          >
            <div className="menu-header">
              {user && user.given_name && (
                <AccountSummary
                  userInitial={user.given_name.charAt(0)}
                  userName={`${user.given_name} ${user.family_name}`}
                  currentlySubscribed={currentlySubscribed}
                  vipOptIn={user.vipOptIn}
                  border={false}
                />
              )}
              <div className="close-icon-wrapper">
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                  className="close-icon button-reset"
                  onClick={hideMenu}
                  type="button"
                  id="close-button"
                >
                  <CrossIcon width={43} height={43} color="#F6F6F6" />
                </button>
              </div>
            </div>
            <div className="menu-body">
              <ul className={`nav-list ${menuActive ? 'menu-active' : ''}`}>
                {modifiedLinks.map(link => (
                  <li
                    onClick={navClickHandler}
                    // onKeyPress={hideMenu}
                    role="none"
                    key={link.path}
                    className={`nav-list-item ${clicked} ${link.path.split('/')[1] === currentPath?.split('/')[1] ? 'active' : ''}`}
                  >
                    <NavLink to={link.path} className="menu-link" activeClassName="active" key={link.path}>
                      <span className="menu-item">
                        {link.title}
                      </span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="login-link">
            <HeaderLoginLink currentDomain={CURRENT_DOMAIN} currentUrl={CURRENT_URL} />
          </div>
          <HamburgerLink
            href="#nav-menu"
            className="nav-toggle"
            active={menuActive}
            onClick={toggleMenu}
          />
        </nav>
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}

HeaderMenu.propTypes = {
  user: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    'custom:subscriptions': PropTypes.string,
    sub: PropTypes.string,
    vipOptIn: PropTypes.bool,
    currentlySubscribed: PropTypes.bool,
  }),
}

HeaderMenu.defaultProps = {
  user: {
    given_name: null,
    family_name: null,
    'custom:subscriptions': '',
    sub: null,
    vipOptIn: false,
    currentlySubscribed: true,
  },
}

const enhanceHeaderMenu = compose(
  withUser
)

export default enhanceHeaderMenu(HeaderMenu)
