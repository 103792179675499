import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
  .avatar-button {
    display: flex;
    width: 100%;
    height: 100%;
    font-weight: 600;
    background: ${colors.stvPurple};
    border: 1px solid ${colors.primaryText};
    justify-content: center;
    box-sizing: border-box;
    border-radius: 50%;
    text-transform: capitalize;
    position: relative;
    vertical-align: middle;
    font-size: inherit;
    color: ${colors.primaryText};
  }

  .avatar-button span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  a.disabled {
    cursor: unset;
  }

`
