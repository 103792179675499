import { getCurrentDomain, getEnvironment } from '../modules/utils' // eslint-disable-line import/no-cycle

export const benefits = [
  'No ads',
  'Watch on multiple devices and platforms',
  'Download to watch offline (iOS only)',
  'Cancel anytime***',
]

export const terms = [
  'Excludes Live TV Channels. Promotions about STV Services may continue to be included from time to time and for contractual reasons some programmes will continue to include adverts.',
]

export const SUBSCRIBED_VIA_STRIPE = 'SUBSCRIBED_VIA_STRIPE'
export const SUBSCRIBED_VIA_IOS = 'SUBSCRIBED_VIA_IOS'
export const SUBSCRIBED_VIA_ROKU = 'SUBSCRIBED_VIA_ROKU'
export const SUBSCRIBED_VIA_GENERIC = 'SUBSCRIBED_VIA_GENERIC'
export const LOADING = 'LOADING'
export const USED_TRIAL = 'USED_TRIAL'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const NEW_PLAYER_USER = 'NEW_PLAYER_USER'
export const ERROR = 'ERROR'
export const STD_DATE_FORMAT = 'DD MMMM YYYY'
export const PENDING_CANCELLED_STATUS = 'pending_cancellation'
export const STRIPE_API_KEY = getEnvironment(getCurrentDomain()) === 'production'
  ? 'pk_live_hbRb7JkS7LzwkzP9BHh4ncA000oJ4Nf7SV'
  : 'pk_test_n3CLSTqIJfHNxg9c5Xscka0F00Ip08Xb2m'
export const STRIPE_MONTHLY_PLAN_ID = getEnvironment(getCurrentDomain()) === 'production'
  ? 'plan_GXWrjBbvQmD5GL'
  : 'plan_F2cK5diyUQrwcm'
export const STRIPE_YEARLY_PLAN_ID = getEnvironment(getCurrentDomain()) === 'production'
  ? 'plan_GXWrGqdLF5SvGo'
  : 'price_1KyCWMG3ZdBdy0nMyEZ5r5k5'
export const STRIPE_PRODUCT_ID = getEnvironment(getCurrentDomain()) === 'production'
  ? 'prod_GXWr36RVi4hHSY'
  : 'prod_F2cKwmaRnjBmX8'
export const MONTHLY_PRICE = 399
export const YEARLY_PRICE = 3999
