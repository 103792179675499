
import css from 'styled-jsx/css'

const baseColors = {
  primary: {
    stvPurple: '#810EA9',
    white: '#fff',
    midGrey1: '#656565',
    black: '#101820',
  },
  secondary: {
    lightGrey1: '#f6f6f6',
    lightGrey2: '#ddd',
    midGrey2: '#767676',
    darkGrey: '#454545',
  },
  utility: {
    heroPurple: '#C448F0',
    lightBlue: '#3db1ff',
    yellow: '#ffc666',
    green: '#68b000',
    red: '#c12a26',
  },
}

const darkBaseColors = {
  primary: {
    purple1: '#810EA9',
    white1: '#F6F6F6',
    grey1: '#151515',
    red: '#D82D31',
  },
  secondary: {
    purple2: '#9916C7',
    grey2: '#212121',
    white2: '#C4C4C4',
  },
  tertiary: {
    purple3: '#C448F0',
    grey3: '#313131',
    white3: '#9E9E9E',
  },
  quaternary: {
    grey4: '#515151',
  },
}

export const colors = {
  // background and divider colors
  baseBg: darkBaseColors.primary.grey1,
  elevatedBg: darkBaseColors.secondary.grey2,
  ctvBg: darkBaseColors.tertiary.grey3,
  textInput: darkBaseColors.quaternary.grey4,
  warning: darkBaseColors.primary.red,
  // text colors
  primaryText: darkBaseColors.primary.white1,
  secondaryText: darkBaseColors.secondary.white2,
  tertiaryText: darkBaseColors.tertiary.white3,

  // purples
  darkPurple: darkBaseColors.primary.purple1, // not used?
  IconButtonPurple: darkBaseColors.secondary.purple2,
  textHoverPurple: darkBaseColors.tertiary.purple3,
  stvPurple: baseColors.primary.stvPurple,

  // misc
  red: '#E16A67',
  green: '#6CAF20',
  promoSuccessGreen: '#68B000',
}

export const errors = css`
.error {
  color: ${colors.red};
  font-size: 14px;
}

.has-error {
  border: 1px solid ${colors.red};
  background: ${colors.textInput};
}

.has-error-password {
  border: 1px solid ${colors.red};
  background: ${colors.textInput};
}

.is-valid {
  border-bottom: 2px solid ${colors.green};
  background: url('/img/valid.png') 95% 50% no-repeat ${colors.textInput} !important;
}

.is-valid-password {
  border-bottom: 2px solid ${colors.green};
  background: url('/img/valid.png') 80% no-repeat ${colors.textInput} !important;
}

.success {
  color: ${colors.primaryText};
  background-color: ${colors.successBg};
  border-color: ${colors.success};
  border: 1px solid transparent;
  border-radius: 4px;
  pointer-events: none;
}

.valid {
  ${colors.green};
}

.tick {
  margin-left: 20px;
}

@media (max-width: 766px) {
  .is-valid-password {
    background: url('/img/valid.png') 95% 50% no-repeat ${colors.textInput} !important;
  }

  .has-error-password {
    background: url(/img/invalid.png) 95% 50% no-repeat ${colors.textInput} ;
  }
}
`
export const tooltipStyles = css`
.tooltip-text {
  text-align: center;
  vertical-align: text-top;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}

.tooltip-content {
  list-style: none;
  top: 40px;
  left: -80px;
  background-color: ${colors.elevatedBg};
  padding: 30px;
  width: 230px;
  position: absolute;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,.2);
}

@media (min-width: 600px){
.tooltip-content {
    list-style: none;
    top: 40px;
    left: 0px;
    background-color: ${colors.elevatedBg};
    padding: 30px;
    width: 270px;
    position: absolute;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.2);
  }
}
`

export const resetCSS = css.global`
  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    color: ${colors.secondaryText};
    background-color: ${colors.baseBg};
  }

  b {
    font-weight: bolder;
  }

  strong {
    font-weight: bold;
  }

  a {
    color: ${colors.textHoverPurple};
    cursor: pointer;
    text-decoration: none;
    font-weight: 800;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #F6F6F6;
    font-weight: 600;
    margin: 0;
  }

  h4 {
    font-size: 18px;
    color: ${colors.primaryText};
    font-weight: 600;
  }

  figure, body {
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
  }

  .center {
    text-align: center !important;
  }

  .no-underline {
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
    font-style: normal;
    font-family: 'Source Sans Pro', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .payment-input.dark:focus {
    outline: ${colors.textHoverPurple} solid 1px;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }


  .join-heading {
    color: ${colors.primaryText};
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 1.125em;
  }

  .join-heading-green {
    color: ${colors.green};
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 1.25em;
    overflow-wrap: break-word;
  }

  .button-wrapper {
    width: 100%;
    text-align: center;
  }

  .disabled:not(.avatar-button) {
    opacity: 0.5;
    background: #313131 !important;
  }

  .tick {
    margin-left: 20px;
    color: ${colors.textHoverPurple};
  }

  .link {
    color: ${colors.textHoverPurple};
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
  }

  .link-no-colour {
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
  }

  .forgot {
    margin: 14px 0;
  }

  .password-show {
    position: absolute;
    top: 0;
    right: 50px;
    margin-top: 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    color: ${colors.textHoverPurple};
  }

  .password-confirm .password-show {
    margin-top: 20px;
  }

  .password-show-inside-field {
    right: 10px;
    margin-left: 0px;
  }

  .tooltip {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #adadad;
    cursor: pointer;
  }

  button.stv-form-button {
    font-size: 18px;
    font-weight: 400;
    border: none;
    line-height: 1.25;
    background: ${colors.IconButtonPurple};
    width: 100%;
    margin: 0 auto;
    color: #fff;
    display: block;
    border-radius: 8px;
    text-align: center;
    outline-offset: 2px;
    transition: background .5s ease-in-out;
    white-space: normal;
    padding: 12px 32px;
    gap: 8px;
    cursor: pointer;
    text-decoration: none;
  }

  button.stv-secondary-form-button {
    font-size: 18px;
    font-weight: 400;
    border: none;
    line-height: 1.25;
    width: 100%;
    margin: 0 auto;
    background ${colors.baseBg};
    color: #F6F6F6;
    display: block;
    text-align: center;
    transition: background .5s ease-in-out;
    white-space: normal;
    padding: 12px 24px;
    cursor: pointer;
    text-decoration: none;
  }

  .stv-form-button:hover {
    background: ${colors.darkPurple};
  }

  .stv-form-button.plan-cancel-button {
    background: ${colors.elevatedBg};
    color: ${colors.primaryText};
    border: 1px solid ${colors.primaryText};
  }

  .stv-form-button.plan-cancel-button:hover {
    background: ${colors.primaryText};
    color: ${colors.elevatedBg};
    border: 1px solid ${colors.elevatedBg};
  }

  .wide {
    width: 195px;
    display: inline-block;
  }

  .error-wrapper {
    height: 20px;
    text-align: left;
  }

  .small-text-wrapper {
    height: 20px;
    text-align: left;
  }

  .error {
    color: ${colors.red};
  }
  .error a{
    color: ${colors.red};
  }
  .under-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .stv-input-description {
    margin-top: 5px;
    color: ${colors.tertiaryText};
    font-size: 14px;
    line-height: 20px;
    display: block;
  }

  .terms-and-button-wrapper {
    position: absolute;
    margin-top: 100px;
    width: 340px;
  }

  .email-used-wrapper {
    border-left: 2px solid ${colors.tertiaryText};
    margin-bottom: 40px;
    text-align: left;
  }

  .grey-text {
    color: ${colors.tertiaryText};
    font-weight: 400;
  }

  .highlighted-text {
    color: ${colors.primaryText};
    font-weight: 600;
  }

  .grey-text, .highlighted-text {
    margin: 0 0 0 5px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-size: 16px !important;
    line-height: 22px;
  }

  .input-description{
    margin: 5px 0 26px 0;
    color: ${colors.secondaryText};
    font-size: 0.9em;
    line-height: 1.6;
    display: block;
    text-align: justify;
    text-justify: inter-word;
   }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('/fonts/source-sans-pro/SourceSansProSemiBold.woff2') format('woff2'),
        url('/fonts/source-sans-pro/SourceSansProSemiBold.woff') format('woff'),
        url('/fonts/source-sans-pro/SourceSansProSemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('/fonts/source-sans-pro/SourceSansPro.woff2') format('woff2'),
        url('/fonts/source-sans-pro/SourceSansPro.woff') format('woff'),
        url('/fonts/source-sans-pro/SourceSansPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('/fonts/source-sans-pro/SourceSansProItalic.woff2') format('woff2'),
        url('/fonts/source-sans-pro/SourceSansProItalic.woff') format('woff'),
        url('/fonts/source-sans-pro/SourceSansProItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('/fonts/source-sans-pro/SourceSansProBold.woff2') format('woff2'),
        url('/fonts/source-sans-pro/SourceSansProBold.woff') format('woff'),
        url('/fonts/source-sans-pro/SourceSansProBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('/fonts/source-sans-pro/SourceSansProSemiBoldItalic.woff2') format('woff2'),
        url('/fonts/source-sans-pro/SourceSansProSemiBoldItalic.woff') format('woff'),
        url('/fonts/source-sans-pro/SourceSansProSemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  .FullWidthWrapper {
    width: 100%;
  }
  .FormWrapperDetails{
    background: ${colors.baseBg};
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .FormWrapper {
    background: ${colors.baseBg};
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .content-wrapper {
    position: absolute;
    top: 35.97px;
    width: 340px;
    height: 100vh;
  }

  .form-head {
    display: table;
    width: 100%;
  }

  .form-heading {
    font-size: 1.125em;
    text-align: center;
    margin: 0;
    color: #fff;
    width: 100%;
    padding: 15.84px 7.92px;
  }

  .button-wrapper {
    margin-top: 12px;
  }

  .reset-links {
    margin: 12px auto 0px;
    display: flex;
    flex-direction: column;
  }

  .reset-links div {
    text-align: center;
    margin-top: 12px
  }

  .text-heading {
    color: ${colors.primaryText};
  }

  .big-heading {
    margin-bottom: 24px;
    text-align: left;
  }

  .stv-form-input-wrapper.name {
    width: -moz-available !important;
    width: -webkit-fill-available !important;
    // width: fill-available !important;
  }

  @media (min-width: 400px){
    h2, h3, .join-heading, .join-heading-green, .form-heading {
      font-size: 1.5em
    }

    .forgot {
      margin-top: 0px;
      display: block;
    }

  }

  @media (min-width: 465px){
    .reset-links {
      align-items: center;
    }

    .reset-links div {
      margin: 0;
      padding-top: 12px;
    }
  }
@media (min-height: 920px){
  .FormWrapperDetails{
  overflow: hidden;
  }
}
  @media screen and (max-height: 750px){
    .FormWrapperDetails{
      overflow: visible !important;
      position: relative !important;
    }
    .FormWrapper {
      overflow: visible !important;
      position: relative !important;
      }
      .terms-and-button-wrapper {
        position: relative !important;
          width: 340px !important;
          margin-top: 30% !important;
          top: 0px !important;
          bottom: 18px;
          margin-left: auto;
          margin-right: auto;
      }
      .content-wrapper {
        position: absolute !important;
      }
      .form-action {
        display: none;
      }
    }
    @media (max-width: 374px){
      .content-wrapper {
        width: 310px !important;
      }
      .terms-and-button-wrapper {
        width: 310px !important;
      }
      .stv-form-input {
        width: 310px !important;
      }
      .stv-form-input.name {
        width: 215px !important;
      }
      .button-wrapper {
        width: 310px !important;
      }
    }

        @media (min-width: 540px){
        .terms-and-button-wrapper {
            position: fixed;
            width: 340px !important;
          }
          .content-wrapper {
            position: fixed;
            width: 340px !important;
          }
          .FormWrapperDetails{
            overflow: hidden;
          }
          .FormWrapper {
            overflow: hidden;
          }

          .stv-form-input.name {
            width: 100% !important;
          }
        }

  @media (min-width: 767px){
    .form-heading {
      font-size: 2em;
    }
.FormWrapperDetails{
    background: ${colors.elevatedBg};
    position: absolute;
    width: 720px;
    height: 900px;
    min-height: 100%;
    left: calc(50% - 720px/2);
    top: 0px;
    display: flex;
    justify-content: center;
}
    .FormWrapper {
      overflow: hidden;
      background: ${colors.elevatedBg};
      position: absolute;
      width: 720px;
      height: auto;
      min-height: 100%;
      left: calc(50% - 720px/2);
      top: 0px;
      display: flex;
      justify-content: center;
    }

    button.stv-secondary-form-button {
      font-size: 18px;
      font-weight: 400;
      border: none;
      line-height: 1.25;
      width: 100%;
      margin: 0 auto;
      background :transparent;
      color: #F6F6F6;
      display: block;
      text-align: center;
      transition: background .5s ease-in-out;
      white-space: normal;
      padding: 12px 24px;
      cursor: pointer;
      text-decoration: none;
    }

    .terms-and-button-wrapper {
      margin-top: 30%;
      position: absolute;
      margin-left: unset;
      margin-right: unset;
      width: 100%;
    }

    .content-wrapper {
      top: 73px;
    }

    .stv-form-input-wrapper.name {
      width: 250px !important;
    }

    .form-content {
      padding: 47px 48px;
      margin-top: unset;
    }

    .form-action {
      position: absolute;
      top: 0;
      right: 0px;
      margin: 12px;
      margin-right: 36px;
      padding: 0 15px 0 0;
      text-align: right;
      width: 1%;
    }

    .form-action.left {
      position: absolute;
      top: 0;
      left: 0px;
      margin: 12px;
      margin-right: 36px;
      padding: 0 15px 0 0;
      text-align: right;
      width: 1%;
    }

    .form-heading, .form-action {
      display: table-cell;
      vertical-align: middle;
    }

    .form-content {
      padding: unset;
      display: flex;
      justify-content: center;
    }

    .form-close {
      width: 38px;
      height: 38px;
      line-height: 40px;
      position: relative;
      color: transparent;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      border-radius: 50%;
      background: ${colors.baseBg};
      font-weight: 700;
      outline: 0;
      cursor: pointer;
      padding: 0;
      border: 0;
    }

    .form-close:after {
      font-size: 32px;
      content: '×';
      color: #fff;
      text-indent: 0;
      position: absolute;
      top: -2px;
      display: block;
      width: 100%;
      font-weight: 300;
      z-index: 5;
    }

    .form-close:hover {
      background: ${colors.elevatedBg};
      border-radius: 100px;
    }

    .form-close:focus {
      outline: 2px solid ${colors.purple} ;
    }

    .form-back {
      width: 38px;
      height: 38px;
      line-height: 40px;
      position: relative;
      color: transparent;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      border-radius: 50%;
      background: ${colors.baseBg};
      font-weight: 700;
      outline: 0;
      cursor: pointer;
      padding: 0;
      border: 0;
    }

    .form-back:after {
      font-size: 32px;
      content: url('/img/Arrow.png');
      color: #fff;
      text-indent: 0;
      position: absolute;
      top: 0px;
      display: block;
      width: 100%;
      font-weight: 300;
      z-index: 5;
    }

    .form-back:hover {
      background: ${colors.elevatedBg};
      border-radius: 100px;
    }

    .form-back:focus {
      outline: 2px solid ${colors.purple} ;
    }

    .input-description{
      font-size: 1em;
     }

     .password-show {
       position: absolute;
       top: 0;
       right: 10px;
       margin-top: 20px;
     }

    .error-wrapper {
      margin-left: 0;
    }

    .small-text-wrapper {
      margin-left: 0;
    }

    .join-heading {
      color: ${colors.primaryText};
      font-weight: 600;
      margin-bottom: 20px;
      font-size: 1.125em;
    }

    .big-heading {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 12px;
      text-align: center;
    }

    .sub-heading {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 24px;
    }
  }

  .terms-and-button-wrapper {
    bottom: 100px;
  }

@media (max-width: 375){
  .content-wrapper {
    width: 310px;
  }
}
  @media (max-width : 375px) // or 213.4375em
    and (max-height : 600px) // or 41.6875em
{
  .terms-and-button-wrapper {
    margin-top: 15%;
  }

  .stv-form-input-wrapper,
  .title-firstname-wrapper {
  }

  .stv-form-input-wrapper.email-wrapper {
    max-width: 310px;
  }


  .FormWrapper {
    overflow: scroll !important;
    height: 700px !important;
  }
}

`
