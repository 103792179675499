/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react'
import qs from 'qs'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import cookies from 'js-cookie'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styles from './styles'
import { getCurrentDomain, getEnvironment } from '../../modules/utils'
import loginService, { getSubscriptionInfo } from '../../initLoginService'
import Spinner from '../../components/Spinner'
import logo from '../Marketing/images/STVPlayerVIP.svg'
import Button from '../../components/Button'
import setPageName from '../../utils/pageName'
import Modal from '../../components/Modal'
import { trackEvent } from '../../modules/dtm-helpers'

const domain = getCurrentDomain()
const env = getEnvironment(domain)
const myDomain = env === 'development' ? 'stvqa.tv' : domain
const VipPage = (props) => {
  const [state, setState] = useState({
    error: null,
    wrapperClassNameSignIn: null,
    wrapperClassNameNotNow: null,
    selectedNewsletter: [],
    optedInEmail: 'false',
  })
  const buttenRef = useRef()
  const userData = useSelector(stateSelc => stateSelc.user)
  const subscriptionInfo = getSubscriptionInfo(userData['custom:subscriptions'])
  const isPlayerUser = (subscriptionInfo.status === 'active' || subscriptionInfo.isTrial)
  const history = useHistory()

  useEffect(() => {
    if (props.newUser) {
      isPlayerUser ? setPageName({
        page: 'onboarding',
        guid: 'vipsubp+',
      })
        : setPageName({
          page: 'onboarding',
          guid: 'vipsub',
        })
    } else {
      isPlayerUser ? setPageName({
        page: 'login',
        guid: 'vipsubp+',
      })
        : setPageName({
          page: 'login',
          guid: 'vipsub',
        })
    }
  }, [isPlayerUser, props.newUser])

  const onHandleSignIn = (e) => {
    e.preventDefault()
    const isVip = (e.target.id === 'signIn')
    const selectedNewsletter = state.selectedNewsletter || '{}'
    const newsLetters = {
      ...selectedNewsletter,
      STV_OptedInEmail: isVip,
    }
    window.digitalData.stv.vip_status = isVip ? 1 : 0

    if (e.target.id === 'signIn') {
      setState({
        wrapperClassNameSignIn: 'updating',
      }, trackEvent('VIPCountMeIn', {}))
    } else if (e.target.id === 'notNow') {
      setState({
        wrapperClassNameNotNow: 'updating',
      },
      trackEvent('VIPNotNow', {}))
    }

    return loginService.updateUserDataMarketing(newsLetters)
      .then((response) => {
        setState({
          wrapperClassNameSignIn: null, wrapperClassNameNotNow: null, error: null, optedInEmail: newsLetters.STV_OptedInEmail,
        })
        cookies.set('STV_VIP_OPTIN', newsLetters.STV_OptedInEmail, { expires: 365, domain: getCurrentDomain() })
        redirect()
      }
      )
      .catch((error) => {
        setState({ error: 'Sorry, something went wrong, please try again.', wrapperClassNameSignIn: null, wrapperClassNameNotNow: null })
        redirect()
      })
  }

  const redirect = () => {
    const {
      siteAction,
      source,
      returnAfterSignIn,
    } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    if (siteAction && siteAction === 'remoteLogin') {
      if (source) {
        window.location.href = decodeURIComponent(source)
        return
      }
      if (Number(returnAfterSignIn)) {
        window.history.back()
        return
      }
    }
    if (siteAction && siteAction === 'localLogin') {
      if (source) {
        history.push(decodeURIComponent(source))
        return
      }
    }
    window.location.assign(`https://player.${myDomain}`)
  }
  return (
    <Modal>
      {() => (
        <div className="success-modal">
          <div className="success-modal-inner">
            <div className="success-modal-contents">
              <div className="image-wrapper">
                <img src={logo} alt="playerVIP logo" />
              </div>
              {props.newUser && <p className="grey-text" style={{ textAlign: 'left' }}>Step 4 of 4</p>}
              <p className="player-title">FREE PERKS!</p>
              <p className="player-plus-subtitle">Sign up to get our free STV Player VIP emails and we’ll keep you in the loop with our new shows and latest TV chat. Plus you’ll get all these added perks without spending a penny:</p>
              <div className="logo-wrapper">
                {!isPlayerUser && (
                  <div className="logo-item">
                    <img src="/img/Fewerads_icons-01.svg" alt="Skip" />
                    <h3 className="gradient-title">Fewer Ads</h3>
                    <h3 className="vip-landing-text">Skip pre-episode ads. Less waiting, more watching!*
                    </h3>
                  </div>
                )}
                <div className="logo-item">
                  <img src="/img/Competition_icons-01.svg" alt="Competitions" />
                  <h3 className="gradient-title">Prize Draws</h3>
                  <h3 className="vip-landing-text">You can be entered into our exclusive prize draws.{isPlayerUser ? '* ' : '**'}
                  </h3>
                </div>

                <div className="logo-item">
                  <img src="/img/Toppicks_icons-01.svg" alt="Top picks" />
                  <h3 className="gradient-title">Top Picks</h3>
                  <h3 className="vip-landing-text">Recommendations based on the shows you love sent to your inbox.
                  </h3>
                </div>
              </div>
              <div className="Ts-Cs-container footer">
                <p className="cardSubtitle">
                  {/* eslint-disable-next-line max-len */}
                  Sign up to receive personalised email marketing, based on the shows you’ve watched, your use of our services and the information you have provided. Opt out at any time.
                </p>
                <p className="cardSubtitle">See our <a href={`https://my.${myDomain}/stv-and-you`} rel="noreferrer noopener" target="_blank">STV &amp; You</a> info page and <a href={`https://my.${myDomain}/terms-of-use/#privacy`} rel="noreferrer noopener" target="_blank">Privacy Policy</a> for information about how and why we collect and use your information. </p>
                {!isPlayerUser ? (
                  <p className="cardSubtitle">
                    *STV Player VIP benefits only apply on platforms where you are signed in to the STV Player. Excludes STV “watch live” Channels. **See our <a href={`https://competitions.${myDomain}/`} rel="noreferrer noopener" target="_blank">Competitions</a> Page. <a href={`https://my.${myDomain}/terms-of-use/#intro`} rel="noreferrer noopener" target="_blank">T&C’s</a> apply.
                  </p>
                ) : (
                  <p className="cardSubtitle">
                    *See our <a href={`https://competitions.${myDomain}/`} rel="noreferrer noopener" target="_blank">Competitions</a> Page. <a href={`https://my.${myDomain}/terms-of-use/#intro`} rel="noreferrer noopener" target="_blank">T&C’s</a> apply.
                  </p>
                )}
                <div className={`button-wrap ${state.wrapperClassNameNotNow ?? ''} ${state.wrapperClassNameSignIn ?? ''}`}>
                  <Button
                    id="signIn"
                    className="marketing-button"
                    variant="primary-purple"
                    onClick={e => onHandleSignIn(e)}
                    ref={buttenRef}
                  >
                    { state.wrapperClassNameSignIn === 'updating' ? (<Spinner width={20} height={20} />) : 'Sign me up'}
                  </Button>
                  <Button
                    id="notNow"
                    variant="secondary"
                    onClick={e => onHandleSignIn(e)}
                    ref={buttenRef}
                  >
                    { state.wrapperClassNameNotNow === 'updating' ? (<Spinner width={20} height={20} />) : 'No thanks'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <style jsx>{styles}</style>
        </div>
      )}
    </Modal>
  )
}

VipPage.propTypes = {
  user: PropTypes.shape({
    birthdate: PropTypes.string,
    'cognito:username': PropTypes.string.isRequired,
  }),
  newUser: PropTypes.bool,
}

VipPage.defaultProps = {
  user: {
    newsletters: {
      STV_OptedInEmail: null,
    },
    birthdate: '1/1/1999',
    'cognito:username': '',
  },
  newUser: false,
}

export default compose(reduxForm({
  form: 'VipPage',
  destroyOnUnmount: true,
}))(VipPage)
