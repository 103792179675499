import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styles from './styles'

const Avatar = ({
  userInitial,
  clickFunction,
}) => {
  const handleClick = clickFunction || (e => e.preventDefault())
  return (
    <Fragment>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="" className={`avatar-button ${clickFunction ? 'enabled' : 'disabled'}`} onClick={handleClick}><span>{userInitial}</span></a>
      <style jsx>{styles}</style>
    </Fragment>
  )
}

Avatar.propTypes = {
  userInitial: PropTypes.string.isRequired,
  clickFunction: PropTypes.func,
}

Avatar.defaultProps = {
  clickFunction: null,
}

export default Avatar
