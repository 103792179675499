import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
  .HeaderMenu {
    background: ${colors.baseBg};
    border: none;
    display: flex;
    padding: 16px 24px;
    position: relative;
    z-index: 3;
    align-items: center;
    transition-duration: 200ms;
    transition-property: padding;
    width: 100%;
  }

  .menu-header {
    display: none;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
  }

  .inner {
    display: flex;
    gap: 32px;
    width: 100%;
  }

  .nav {
    display: flex;
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    gap: 16px;
  }



  .nav-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    background: transparent none;
    border-bottom: none;
    background: none transparent;
    box-shadow: none;
    list-style: none;
    gap: 16px;
    // width: 100%;
    position: absolute;
    left: -80px;
    right: 0;
    top: 0;
    bottom: 0;
    width: fit-content;
    margin: auto;
  }

  .nav :global(.menu-link){
    display: flex;
    align-items: center;
    padding: 0 12px;
    border-radius: 8px;
    height: 35px;
    margin: 0;
  }

  .nav :global(.menu-link:hover:not(.active)),
  .nav :global(.menu-link:focus:not(.active)) {
    background: ${colors.ctvBg};
  }

  .nav :global(.menu-link.active) {
    background: ${colors.primaryText};
  }

  li.nav-list-item.Upgrade, li.nav-list-item.Subscribe {
    display: none;
  }

  .nav :global(.menu-link) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.11px;
    color: ${colors.primaryText};
  }

  .nav :global(.menu-link.active) {
    color: ${colors.textInput};
    font-weight: 600;
  }

  .accountMenu :global(.menu-link) {
    font-weight: 600;
  }

  .login-link {
    margin-left: auto;
  }

  @media (min-width: 1200px) {
    .HeaderMenu {
      align-items: center;
      padding: 16px 48px;
    }

    .nav {
      align-self: stretch;
      padding: 0;
    }


  }

  @media (max-width: 1199px) {
    .nav {
      gap: 12px;
    }
  }

  @media (max-width: 1023px) {
    li.nav-list-item {
      text-align: center;
      width: 270px;
      border-radius: 8px;
      // margin-bottom: 10px;
      justify-content: center;
    }

    .nav-container {
      background: rgba(21, 21, 21, 0.75);
      backdrop-filter: blur(10px);
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      overflow: scroll;
      padding-bottom: 48px;
      padding-top: 48px;
      overflow-x: hidden;
    }

    .nav :global(.menu-link){
      display: flex;
      justify-content: center;
    }

    .menu-header {
      display: flex;
      align-items: center;
      padding-right: 16px;
    }

    .menu-body {
      padding-top: 48px;
      display: flex;
      justify-content: center;
    }

    .nav-list, .nav-container {
      display: none;
    }

    .nav-list.menu-active, .nav-container.menu-active {
      display: block;
    }

    .nav-list {
      position: unset;
    }

    li.nav-list-item {
      margin-bottom: 10px;
    }

    .close-icon-wrapper {
      margin-left: auto;
      width: 43px;
      height: 43px;
    }

    button#close-button.close-icon {
      background: transparent;
      border: none;
      padding: none;
    }

  }

  @media (max-width: 767px) {
    .HeaderMenu {
      padding: 9px;
    }

    li.nav-list-item.Upgrade {
      display: flex;
    }

    .nav {
      gap: 4px;
    }
  }
`
