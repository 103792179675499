/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import cookies from 'js-cookie'
import { ClientOnly } from '../SSRSwitch'
import loginService, { getSubscriptionInfo } from '../../initLoginService'
import { trackEvent } from '../../modules/dtm-helpers'
import { Avatar as AvatarIcon, CrossIcon } from '../icons'
import getLoggedOutUrl from '../../utils/getLoggedOutUrl'
import styles from './styles'
import Error from '../Error'
import AccountSummary from '../AccountSummary'
import Avatar from '../Avatar'

const HeaderLoginLink = ({
  currentDomain,
  currentUrl,
  history,
  user,
  clearUser,
  isLoggedIn,
}) => {
  const [menuIsActive, setMenuIsActive] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isVip, setIsVip] = useState(false)
  const [playerPlusMessage, setPlayerPlusMessage] = useState(null)
  const [userLoading, setUserLoading] = useState(true)

  useEffect(() => {
    const checkIfSubscribed = async (updatedUser) => {
      try {
        const subscriptionInfo = getSubscriptionInfo(updatedUser['custom:subscriptions'])
        const { currentlySubscribed } = subscriptionInfo

        if (currentlySubscribed === true) {
          setIsSubscribed(true)
        }
        setUserLoading(false)
      } catch (error) {
        setPlayerPlusMessage('Error')
      }
    }

    checkIfSubscribed(user)
    setIsVip(cookies.get('STV_VIP_OPTIN') === 'true')
  }, [user])

  useEffect(() => {
    if (window && window.innerWidth < 768) {
      if (menuIsActive) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = null
      }
    }
  }, [menuIsActive])

  const logOut = () => {
    const { location, push } = history
    loginService.logOut()
    trackEvent('logOutSuccess', {})
    clearUser()
    push(`/?siteAction=localLogin&source=${encodeURIComponent(location.pathname)}`)
  }

  const handleMenuClick = (e) => {
    e.preventDefault()
    document.getElementsByClassName('IconLink')[0].classList.remove('active')
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('ul.menu-active')?.classList?.remove('menu-active')
    toggleMenu()
  }

  const toggleMenu = () => setMenuIsActive(!menuIsActive)

  const onBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      if (window && window.innerWidth > 767) {
        closeMenu()
      }
    }
  }

  const closeMenu = () => setMenuIsActive(false)

  const profileHost = getLoggedOutUrl(currentDomain)

  if (Object.keys(user).length === 0) return null
  switch (playerPlusMessage) {
    case 'Error':
      return (<Error />)
    default:
      return (
        <ClientOnly>
          <div className={`Login ${menuIsActive ? 'active' : ''}`} onBlur={onBlur}>
            {!isSubscribed && !userLoading && (
              <div className="player-plus">
                <a href="https://player.stv.tv/player-plus" onClick={() => { trackEvent('PplusNavUpgrade', { pplus_nav_upgrade: true }) }}>
                  Subscribe to <strong>STV Player+</strong>
                </a>
              </div>
            )}
            {
              isLoggedIn && (
                <Fragment>
                  <div className={`avatar-outer ${menuIsActive ? 'active' : 'inactive'}`}>
                    <div className="avatar-container">
                      {/* eslint-disable-next-line max-len */}
                      <Avatar userInitial={user.given_name.charAt(0)} clickFunction={handleMenuClick} />
                    </div>
                  </div>
                  <div className={`menu-container ${menuIsActive ? 'active' : ''}`}>
                    <div className="menu-header">
                      <AccountSummary
                        userInitial={user.given_name.charAt(0)}
                        userName={`${user.given_name} ${user.family_name}`}
                        currentlySubscribed={isSubscribed}
                        vipOptIn={isVip}
                      />
                      <div className="close-icon-wrapper">
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                          className="close-icon button-reset"
                          onClick={closeMenu}
                          type="button"
                          id="close-button"
                        >
                          <CrossIcon width={43} height={43} color="#F6F6F6" />
                        </button>
                      </div>
                    </div>
                    <div className="menu-body">
                      {/* <ul className={`accountMenu ${state.menuIsActive ? 'active' : ''}`}> */}
                      <ul className="accountMenu">
                        <li className="menu-item">
                          <a className="account-menu-link" href={`https://player.${currentDomain}/`}>STV Player</a>
                        </li>
                        <li className="menu-item">
                          <a className="account-menu-link" href="/plan">{`${isSubscribed ? 'Plan Details' : 'Subscribe to STV Player+'}`}</a>
                        </li>
                        <li className="menu-item">
                          <a className="account-menu-link" href="/activate">Activate Your TV</a>
                        </li>
                        <li className="menu-item">
                          <a className="account-menu-link" href={`https://player.${currentDomain}/settings`}>Parental Controls</a>
                        </li>
                        <li className="menu-item">
                          <button
                            className="account-menu-link"
                            type="button"
                            onClick={logOut}
                          >
                            Sign Out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Fragment>
              )
            }
            {
              isLoggedIn === false && (
                <Fragment>
                  <div className="avatar signedOut"><AvatarIcon /></div>
                  <a
                    // eslint-disable-next-line max-len
                    href={`${profileHost}?siteAction=remoteLogin&source=${encodeURIComponent(currentUrl)}`}
                  >
                    Sign in <span className="accountText">/Join</span>
                  </a>
                </Fragment>
              )
            }
            <style jsx>{styles}</style>
            <style jsx>{styles}</style>
          </div>
        </ClientOnly>
      )
  }
}

HeaderLoginLink.propTypes = {
  currentDomain: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  currentUrl: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  }).isRequired,
  user: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string,
  }).isRequired,
  clearUser: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
}

HeaderLoginLink.defaultProps = {
  isLoggedIn: true,
  // eslint-disable-next-line react/default-props-match-prop-types
  currentUrl: '',
}

export default HeaderLoginLink
