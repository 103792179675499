const links = [
  {
    path: '/personal-details',
    title: 'Personal Details',
  },
  {
    path: '/plan',
    title: 'Plan',
  },
  {
    path: '/change-password',
    title: 'Change Password',
  },
  {
    path: '/marketing',
    title: 'Marketing',
  },
  {
    path: '/privacy',
    title: 'Privacy',
  },

]
export default links
