import LoginService, { getSubscriptionInfo } from '@stvgroup/login-service'
import { getCurrentDomain, getEnvironment, isSecureProtocol } from './modules/utils'

const loginService = LoginService({
  currentDomain: getCurrentDomain(),
  environment: getEnvironment(getCurrentDomain()),
  isSecureProtocol: isSecureProtocol(),
})


export default loginService
export { getSubscriptionInfo }
